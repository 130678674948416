import React, { useEffect, useState } from "react";
import socketIo from "socket.io-client";
import Layout from "./layout";
import store from "./redux/Store";
import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";
import TagManager from 'react-gtm-module';
import {isAuthenticated} from "./utils";
import CustomModal from "./hoc/CustomModal";
import UpdatePop from "./layout/UpdatePop";

function App({ children, history }) {
  const cookies = new Cookies();
  const [updatePop, setUpdatePop] = useState(false);
  const userDetails = useSelector(state => state.SigninModel);
  const { dispatch } = store;
  
  const tagManagerArgs = {
    gtmId: 'GTM-NL7PQ9JS'
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (userDetails && Object.keys(userDetails || {}).length > 0) {
      const sock = socketIo(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket'], query: `user=${userDetails?._id}` });
      dispatch.SocketModel.setSocketData(sock);
    }
  }, [userDetails]);

  useEffect(() => {
    const token = cookies.get(`${process.env.REACT_APP_PREFIX}x-access-token`);
    if (token) {
      dispatch.SigninModel.getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      if (userDetails && Object.keys(userDetails || {}).length > 0) {
        dispatch.SigninModel.getLatestVersion(setUpdatePop);
      }
    }
  }, [history.location.pathname, userDetails]);

  const updateSiteVersion = () => {
    dispatch.SigninModel.updateVersion(setUpdatePop);
  };

  return (
    <Layout history={history} >
      {children}
      <CustomModal maskClosable={false} closable={false} className="update_correct popup_update" handleOpen={setUpdatePop} open={updatePop}>
        <UpdatePop updateSiteVersion={updateSiteVersion} handleOpenModal={setUpdatePop} />
      </CustomModal>
    </Layout>
  );
}

export default App;
