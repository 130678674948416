import Cookies from 'universal-cookie';
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

const cookies = new Cookies();

export const getAccessToken = () =>
  cookies.get(`${process.env.REACT_APP_PREFIX}x-access-token`);
export const isAuthenticated = () => !!getAccessToken();

export const requiredFieldValidator = (fields, formData, type) => {
  let errors = {};
  if (type) {
    formData.map((data, index) => {
      fields.map((field) => {
        if (!data[field.name]) {
          if (errors[type]) {
            errors[type] = [
              ...errors[type],
              { id: index, [field.name]: `${field.fieldName} is required` }
            ];
          } else {
            errors[type] = [
              { id: index, [field.name]: `${field.fieldName} is required` }
            ];
          }
        }
      });
    });
  } else {
    fields.map((field) => {
      let check = /^[a-zA-Z]*$/.test(formData[field.name]);
      if (!formData[field.name]) {
        errors[field.name] = `${field.fieldName} is required`;
      } else if (!check) {
        errors[
          field.name
        ] = `Numeric and special characters are not allowed here`;
      }
    });
  }
  return errors;
};

export const customFieldValidator = (fields, formData) => {
  let errors = {};
  fields.map((field) => {
    switch (field.rule) {
      case 'number': {
        let number = /^[0-9]*$/.test(formData[field.name]);
        if (!formData[field.name]) {
          errors[field.name] = `${field.fieldName} is required`;
        } else if (!number) {
          errors[field.name] = `Only numbers are allowed`;
        }
        break;
      }
      case 'textarea': {
        if (!formData[field.name]) {
          errors[field.name] = `${field.fieldName} is required`;
        } else if (
          formData[field.name].length > 300 ||
          formData[field.name].length < 100
        ) {
          errors[
            field.name
          ] = `${field.fieldName} must be max 300 character and min 100 char long`;
        }
        break;
      }
      case 'specialChar': {
        let specialChar = /^[a-zA-Z ]*$/.test(formData[field.name]);
        if (!formData[field.name]) {
          errors[field.name] = `${field.fieldName} is required`;
        } else if (!specialChar) {
          errors[
            field.name
          ] = `Numeric and special characters are not allowed here`;
        }
        break;
      }
      case 'email': {
        let checkEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            formData[field.name]
          );
        if (!formData[field.name]) {
          errors[field.name] = `${field.fieldName} is required`;
        } else if (!checkEmail) {
          errors[field.name] = `Email is invalid`;
        }
        break;
      }
      case 'link': {
        let checkLink =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            formData[field.name]
          );
        if (!formData[field.name]) {
          return false;
        } else if (!checkLink) {
          errors[field.name] = `Please upload valid link`;
        }
        break;
      }
      default: {
        if (!formData[field.name]) {
          errors[field.name] = `${field.fieldName} is required`;
        }
        break;
      }
    }
  });
  return errors;
};

export function toTitleCase(str) {
  if (typeof str === 'string') {
    let splitStr = str?.toLowerCase()?.split('.');
    for (let i = 0; i < splitStr?.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i]?.replaceAll('\r', '').trim().charAt(0)?.toUpperCase() +
        splitStr[i]?.replaceAll('\r', '').trim().substring(1);
    }
    // Directly return the joined string
    return splitStr?.join('. ');
  }
  return str;
}

export const removeInlineStyle = (msg) => {
  if (msg) {
    const div = document.createElement('div');
    div.innerHTML = msg;
    let tags = [...div.getElementsByTagName('*')];
    tags?.map((tag) => tag.removeAttribute('style'));
    return div.innerHTML;
  }
  return '';
};

export const removeMeta = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  let length = div.getElementsByClassName('meta').length - 1;
  if (length >= 0) {
    do {
      div.getElementsByClassName('meta')[length].remove();
      length -= 1;
    } while (length !== -1);
  }

  return div.innerHTML === 'undefined' ? '' : div.innerHTML;
};

export const urlify = (text) => {
  let urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text?.match(urlRegex)?.[0];
};

export const getMetaContent = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const meta = [...div.getElementsByTagName('meta')];
  let metaData = {};
  meta?.map((data) => {
    if (data.getAttribute('property')) {
      metaData = {
        ...metaData,
        [data.getAttribute('property')]: data.getAttribute('content')
      };
    } else {
      return null;
    }
  });
  return metaData;
};

export const generateAvatar = (text) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Draw background
  context.fillStyle = stringToHslColor(text);
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw text
  context.font = 'bold 75px Assistant';
  context.fillStyle = '#fff';
  context.textAlign = 'center';
  context.textBaseline = 'middle';

  let first = text?.split(' ')[0]?.charAt(0)?.toUpperCase();
  let last = text?.split(' ')[1]?.charAt(0)?.toUpperCase();

  if (!last) {
    last =
      text?.split(' ')[0]?.charAt(1)?.toUpperCase() ||
      text?.split(' ')[0]?.charAt(0)?.toUpperCase();
  }
  if (!first) {
    first = 'S';
    last = 'U';
  }

  context.fillText(first + last, canvas.width / 2, canvas.height / 2);

  return canvas.toDataURL('image/png');
};

export const stringToHslColor = (str, s = 30, l = 80) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const checkRchEmpty = (html) => {
  let div = document.createElement('div');
  div.innerHTML = html;
  let isImage = div?.getElementsByTagName?.('img')?.length > 0;
  if (isImage) {
    return false;
  }
  return (
    div.innerText.replaceAll('\n', '').trim() === '' ||
    div.innerText.replaceAll('\n', '').trim() === 'undefined'
  );
};

export function compare(a, b) {
  if (a.id > b.id) {
    return -1;
  }
  if (a.id < b.id) {
    return 1;
  }
  return 0;
}

export const nameReplace = (name, changeName) => {
  if (name === changeName) {
    return 'USC Mentor';
  }
  return name;
};

export const replaceLink = (html) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = (html || '').replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export function formatDate(value) {
    var m = value.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
  return (m) ? new Date(m[3], m[2]-1, m[1]) : null;
}

export const normalizeDate = (phone) => {
  // let cleaned = phone.replace(/[^\w\s]/gi, '');
  // return cleaned.replace(' ', '');
  return phone;
};


export const getEditorState = (html) => {
  const blocksFromHtml = htmlToDraft(removeInlineStyle(html) || "<p></p>");
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
};
