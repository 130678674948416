import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../src/assets/images/logo.png';
import store from "../../redux/Store";
// import logo from '../../assets/images/USC-CA.png'
import logo_usc from '../../assets/images/logo_usc.svg'
import { ReactComponent as UscTextLogo } from "../../assets/images/usc_online_text.svg";
import Cookies from 'universal-cookie';

const Header = ({ isAuthenticated, handleLogout, history }) => {
    const socket = useSelector(state => state.SocketModel.socket);
    const userData = useSelector(state => state.SigninModel);
    const cookies = new Cookies();
    const userDetsailsLocal = cookies.get(`${process.env.REACT_APP_PREFIX}u_id`);
    const { dispatch } = store;

    useEffect(() => {
        if (Object.keys(socket).length) {
            socket.on('connect', () => {
                console.log('connection');
            });
            socket.off('connect_failed').on('connect_failed', function (err) {
                console.log('Connection Failed', err);
            });
        }
    }, [socket]);

    useEffect(() => {
        if (Object.keys(socket || {}).length) {
            socket.on('new_post_data', socketListener);
        }
        return () => {
            if (Object.keys(socket || {}).length) {
                socket.off('new_post_data', socketListener);
            }
        }
    }, [socket]);

    const socketListener = (res) => {
        const course_id = history.location.pathname.split('/');
        if (res.course_id === course_id?.[2]) {
            switch(res.type) {
                case "preDelete": {
                    dispatch.CourseDetailModel.deleteComment({ ...res.post, call_type: "socket" });
                    break;
                }
                case "preEditComment": {
                    dispatch.CourseDetailModel.editComment(res.post);
                    break;
                }
                case "preComment": {
                    dispatch.CourseDetailModel.addComment({...res.post, call_type: "socket"});
                    break;
                }
                default : {
                    return;
                }
            }
        }
    };
    const programid  = cookies.get('programid')

    const redirecthandler = () =>{
       
        if(isAuthenticated()){
            if(userData?.programID || userData?.programid){
                if(userData?.free_applied)
                    history.push(`/freeapply/${userData?.programID  || userData?.programid ? userData?.programID  || userData?.programid : programid}`) 
                else
                    history.push(`/apply/${userData?.programID  || userData?.programid ? userData?.programID  || userData?.programid : programid}`) 
            }else{
                history.push(`/apply`)
            } 
        }else{
            history.push(`/`)
        }
    }
    return (
        <>
            <header>
                <div className="main_header cartificate_header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 top_header">
                            <div onClick={() =>redirecthandler()} className="content_head cursor_pointer">
                                    {/* <h1>usc online certificates</h1> */}
                                    <div className="logo art_logo new_art_logo">
                                        <h1>USC Distinguished Leaders</h1>
                                    </div>
                                </div>
                                <div className="logo art_logo">{isAuthenticated() && userData?.first_name ?
                                    <div className='header_menu'>
                                        <p className='header_wcm_text'>welcome {userData?.first_name} {userData?.last_name} <span className='logout_text ml_2'><a onClick={handleLogout}>Log out</a></span> </p>
                                    </div>
                                    : ''}
                                    <a href="https://www.usc.edu/" target='_blank'>
                                    <img  onClick={() => window.open("https://www.usc.edu/")} src={logo} alt="Logo" /></a>
                                    {/* <UscTextLogo onClick={() => window.open("https://online.usc.edu/")}  className='Certificate_Logo' /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
};

export default Header