import axiosClient from "../../api/axiosClient";
import Cookies from 'universal-cookie';

const ResetPassword = {
    state: {},
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async verifyUserReset(payload) {
            const { token, setFormData, history } = payload;
            const cookies = new Cookies();
            const programid  = cookies.get('programid')
            axiosClient.get(`/dl_verified_token/${token}`).then(res => {
                if (res.data.status === 200) {
                    setFormData((old) => { return { ...old, email: res.data.email } });
                } else if (res.data.status === 400) {
                    history.push(`/apply/${programid}`);
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async resetPassword(payload) {
            const { data, history } = payload;
            axiosClient.put('/dlResetPassword', data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: res.data.message });
                    history.push('/signin');

                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.response.data.message });
            });
        },
    }),
};

export default ResetPassword;