import Header from './Header';
import Footer from './Footer';
import React, { Suspense } from 'react';
import { isAuthenticated } from "../utils";
import CustomModal from "../hoc/CustomModal";
import ErrorPop from "../PopContent/ErrorPop";
import { useSelector } from "react-redux";
import store from "../redux/Store";
import Cookies from 'universal-cookie';

const Layout = ({ children, history }) => {
    const cookies = new Cookies();
    const { dispatch } = store;
    const error = useSelector(state => state.ErrorModel);

    const handleLogout = () => {    
        var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
        cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}u_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}rise_course_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}rise_topic_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}outline_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}outline_ids`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}user_name`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${process.env.REACT_APP_PREFIX}certificate_clg`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
        cookies.remove(`${'programid'}`, { path: '/', expires: d,  secure: true, sameSite: 'strict' });
        cookies.remove(`${'free_applied'}`, { path: '/', expires: d,  secure: true, sameSite: 'strict' });
        localStorage.clear();
        store.dispatch({ type: 'RESET' });
        history.push('/signin');
    };

    return (
        <div className="layout">
            <Header history={history} isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
            <section className={isAuthenticated() ? "page_wrapper" : " inner_wrapper"}>
                <Suspense fallback={""}>
                    {children}
                </Suspense>
            </section>
            <Footer />
            <CustomModal handleOpen={dispatch.ErrorModel.handleClose} open={error.isOpen}>
                <ErrorPop handleOpenModal={dispatch.ErrorModel.handleClose} error={error.error} />
            </CustomModal>
        </div>
    )
};

export default Layout;