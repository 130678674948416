
const ApplicationSuccess = () => {


  return (
    <div className='course_payment'>
      <h3>Your application has been successfully submitted.</h3>
      <p>
        We will review your application within 3 - 5 business days. Please check your email for further instructions.
      </p>
    </div>
  );
};

export default ApplicationSuccess;
