export const countries = [
  {
    label: "United States",
    value: "USA",
    code: "+1",
  },
  {
    label: "Afghanistan",
    value: "AFG",
    code: "+93",
  },
  {
    label: "Albania",
    value: "ALB",
    code: "+355",
  },
  {
    label: "Algeria",
    value: "DZA",
    code: "+213",
  },
  {
    label: "American Samoa",
    value: "ASM",
    code: "+1-684",
  },
  {
    label: "Andorra",
    value: "AND",
    code: "+376",
  },
  {
    label: "Angola",
    value: "AGO",
    code: "+244",
  },
  {
    label: "Anguilla",
    value: "AIA",
    code: "+1",
  },
  {
    label: "Antarctica",
    value: "ATA",
    code: "+672",
  },
  {
    label: "Antigua and Barbuda",
    value: "ATG",
    code: "+1-268",
  },
  {
    label: "Argentina",
    value: "ARG",
    code: "+54",
  },
  {
    label: "Armenia",
    value: "ARM",
  },
  {
    label: "Aruba",
    value: "ABW",
    code: "+297",
  },
  {
    label: "Australia",
    value: "AUS",
    code: "+61",
  },
  {
    label: "Austria",
    value: "AUT",
    code: "+43",
  },
  {
    label: "Azerbaijan",
    value: "AZE",
    code: "+994",
  },
  {
    label: "Bahamas",
    value: "BHS",
    code: "+1",
  },
  {
    label: "Bahrain",
    value: "BHR",
    code: "+973",
  },
  {
    label: "Bangladesh",
    value: "BGD",
    code: "+880",
  },
  {
    label: "Barbados",
    value: "BRB",
    code: "+1",
  },
  {
    label: "Belarus",
    value: "BLR",
    code: "+375",
  },
  {
    label: "Belgium",
    value: "BEL",
    code: "+32",
  },
  {
    label: "Belize",
    value: "BLZ",
    code: "+501",
  },
  {
    label: "Benin",
    value: "BEN",
    code: "+229",
  },
  {
    label: "Bermuda",
    value: "BMU",
    code: "+1",
  },
  {
    label: "Bhutan",
    value: "BTN",
    code: "+975",
  },
  {
    label: "Bolivia, Plurinational State of",
    value: "BOL",
    code: "+591",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BES",
    code: "+599",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BIH",
    code: "+387",
  },
  {
    label: "Botswana",
    value: "BWA",
    code: "+267",
  },
  {
    label: "Bouvet Island",
    value: "BVT",
  },
  {
    label: "Brazil",
    value: "BRA",
    code: "+55",
  },
  {
    label: "British Indian Ocean Territory",
    value: "IOT",
    code: "+246",
  },
  {
    label: "Brunei Darussalam",
    value: "BRN",
    code: "+673",
  },
  {
    label: "Bulgaria",
    value: "BGR",
    code: "+359",
  },
  {
    label: "Burkina Faso",
    value: "BFA",
    code: "+226",
  },
  {
    label: "Burundi",
    value: "BDI",
    code: "+257",
  },
  {
    label: "Cambodia",
    value: "KHM",
    code: "+855",
  },
  {
    label: "Cameroon",
    value: "CMR",
    code: "+237",
  },
  {
    label: "Canada",
    value: "CAN",
    code: "+1",
  },
  {
    label: "Cape Verde",
    value: "CPV",
    code: "+238",
  },
  {
    label: "Cayman Islands",
    value: "CYM",
    code: "+1",
  },
  {
    label: "Central African Republic",
    value: "CAF",
    code: "+236",
  },
  {
    label: "Chad",
    value: "TCD",
    code: "+235",
  },
  {
    label: "Chile",
    value: "CHL",
    code: "+56",
  },
  {
    label: "China",
    value: "CHN",
    code: "+86",
  },
  {
    label: "Christmas Island",
    value: "CXR",
    code: "+61",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "CCK",
    code: "+61",
  },
  {
    label: "Colombia",
    value: "COL",
    code: "+57",
  },
  {
    label: "Comoros",
    value: "COM",
    code: "+269",
  },
  {
    label: "Congo",
    value: "COG",
    code: "242",
  },
  {
    label: "Congo, the Democratic Republic of the",
    value: "COD",
    code: "243",
  },
  {
    label: "Cook Islands",
    value: "COK",
    code: "682",
  },
  {
    label: "Costa Rica",
    value: "CRI",
    code: "506",
  },
  {
    label: "Croatia",
    value: "HRV",
    code: "385",
  },
  {
    label: "Cuba",
    value: "CUB",
    code: "53",
  },
  {
    label: "Curaçao",
    value: "CUW",
    code: "599",
  },
  {
    label: "Cyprus",
    value: "CYP",
    code: "357",
  },
  {
    label: "Czech Republic",
    value: "CZE",
    code: "420",
  },
  {
    label: "Côte d'Ivoire",
    value: "CIV",
    code: "225",
  },
  {
    label: "Denmark",
    value: "DNK",
    code: "45",
  },
  {
    label: "Djibouti",
    value: "DJI",
    code: "253",
  },
  {
    label: "Dominica",
    value: "DMA",
    code: "1-767",
  },
  {
    label: "Dominican Republic",
    value: "DOM",
    code: "1-809, 1-829, 1-849",
  },
  {
    label: "Ecuador",
    value: "ECU",
    code: "593",
  },
  {
    label: "Egypt",
    value: "EGY",
    code: "20",
  },
  {
    label: "El Salvador",
    value: "SLV",
    code: "503",
  },
  {
    label: "Equatorial Guinea",
    value: "GNQ",
    code: "240",
  },
  {
    label: "Eritrea",
    value: "ERI",
    code: "291",
  },
  {
    label: "Estonia",
    value: "EST",
    code: "372",
  },
  {
    label: "Ethiopia",
    value: "ETH",
    code: "251",
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "FLK",
    code: "500",
  },
  {
    label: "Faroe Islands",
    value: "FRO",
    code: "298",
  },
  {
    label: "Fiji",
    value: "FJI",
    code: "679",
  },
  {
    label: "Finland",
    value: "FIN",
    code: "358",
  },
  {
    label: "France",
    value: "FRA",
    code: "33",
  },
  {
    label: "French Guiana",
    value: "GUF",
    code: "594",
  },
  {
    label: "French Polynesia",
    value: "PYF",
    code: "689",
  },
  {
    label: "French Southern Territories",
    value: "ATF",
    code: "262",
  },
  {
    label: "Gabon",
    value: "GAB",
    code: "241",
  },
  {
    label: "Gambia",
    value: "GMB",
    code: "220",
  },
  {
    label: "Georgia",
    value: "GEO",
    code: "995",
  },
  {
    label: "Germany",
    value: "DEU",
    code: "49",
  },
  {
    label: "Ghana",
    value: "GHA",
    code: "233",
  },
  {
    label: "Gibraltar",
    value: "GIB",
    code: "350",
  },
  {
    label: "Greece",
    value: "GRC",
    code: "30",
  },
  {
    label: "Greenland",
    value: "GRL",
    code: "299",
  },
  {
    label: "Grenada",
    value: "GRD",
    code: "1-473",
  },
  {
    label: "Guadeloupe",
    value: "GLP",
    code: "590",
  },
  {
    label: "Guam",
    value: "GUM",
    code: "1-671",
  },
  {
    label: "Guatemala",
    value: "GTM",
    code: "502",
  },
  {
    label: "Guernsey",
    value: "GGY",
    code: "44",
  },
  {
    label: "Guinea",
    value: "GIN",
    code: "224",
  },
  {
    label: "Guinea-Bissau",
    value: "GNB",
    code: "245",
  },
  {
    label: "Guyana",
    value: "GUY",
    code: "592",
  },
  {
    label: "Haiti",
    value: "HTI",
    code: "509",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "HMD",
    code: "",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "VAT",
    code: "379",
  },
  {
    label: "Honduras",
    value: "HND",
    code: "504",
  },
  {
    label: "Hong Kong",
    value: "HKG",
    code: "852",
  },
  {
    label: "Hungary",
    value: "HUN",
    code: "36",
  },
  {
    label: "Iceland",
    value: "ISL",
    code: "354",
  },
  {
    label: "India",
    value: "IND",
    code: "91",
  },
  {
    label: "Indonesia",
    value: "IDN",
    code: "62",
  },
  {
    label: "Iran, Islamic Republic of",
    value: "IRN",
    code: "98",
  },
  {
    label: "Iraq",
    value: "IRQ",
    code: "964",
  },
  {
    label: "Ireland",
    value: "IRL",
    code: "353",
  },
  {
    label: "Isle of Man",
    value: "IMN",
    code: "44",
  },
  {
    label: "Israel",
    value: "ISR",
    code: "972",
  },
  {
    label: "Italy",
    value: "ITA",
    code: "39",
  },
  {
    label: "Jamaica",
    value: "JAM",
    code: "1-876",
  },
  {
    label: "Japan",
    value: "JPN",
    code: "81",
  },
  {
    label: "Jersey",
    value: "JEY",
    code: "44",
  },
  {
    label: "Jordan",
    value: "JOR",
    code: "962",
  },
  {
    label: "Kazakhstan",
    value: "KAZ",
    code: "7",
  },
  {
    label: "Kenya",
    value: "KEN",
    code: "254",
  },
  {
    label: "Kiribati",
    value: "KIR",
    code: "686",
  },
  {
    label: "Korea, Democratic People's Republic of",
    value: "PRK",
    code: "850",
  },
  {
    label: "Korea, Republic of",
    value: "KOR",
    code: "82",
  },
  {
    label: "Kuwait",
    value: "KWT",
    code: "965",
  },
  {
    label: "Kyrgyzstan",
    value: "KGZ",
    code: "996",
  },
  {
    label: "Lao People's Democratic Republic",
    value: "LAO",
    code: "856",
  },
  {
    label: "Latvia",
    value: "LVA",
    code: "371",
  },
  {
    label: "Lebanon",
    value: "LBN",
    code: "961",
  },
  {
    label: "Lesotho",
    value: "LSO",
    code: "266",
  },
  {
    label: "Liberia",
    value: "LBR",
    code: "231",
  },
  {
    label: "Libyan Arab Jamahiriya",
    value: "LBY",
    code: "218",
  },
  {
    label: "Liechtenstein",
    value: "LIE",
    code: "423",
  },
  {
    label: "Lithuania",
    value: "LTU",
    code: "370",
  },
  {
    label: "Luxembourg",
    value: "LUX",
    code: "352",
  },
  {
    label: "Macao",
    value: "MAC",
    code: "853",
  },
  {
    label: "Macedonia, the former Yugoslav Republic of",
    value: "MKD",
    code: "389",
  },
  {
    label: "Madagascar",
    value: "MDG",
    code: "261",
  },
  {
    label: "Malawi",
    value: "MWI",
    code: "265",
  },
  {
    label: "Malaysia",
    value: "MYS",
    code: "60",
  },
  {
    label: "Maldives",
    value: "MDV",
    code: "960",
  },
  {
    label: "Mali",
    value: "MLI",
    code: "223",
  },
  {
    label: "Malta",
    value: "MLT",
    code: "356",
  },
  {
    label: "Marshall Islands",
    value: "MHL",
    code: "692",
  },
  {
    label: "Martinique",
    value: "MTQ",
    code: "596",
  },
  {
    label: "Mauritania",
    value: "MRT",
    code: "222",
  },
  {
    label: "Mauritius",
    value: "MUS",
    code: "230",
  },
  {
    label: "Mayotte",
    value: "MYT",
    code: "262",
  },
  {
    label: "Mexico",
    value: "MEX",
    code: "52",
  },
  {
    label: "Micronesia, Federated States of",
    value: "FSM",
    code: "691",
  },
  {
    label: "Moldova, Republic of",
    value: "MDA",
    code: "373",
  },
  {
    label: "Monaco",
    value: "MCO",
    code: "377",
  },
  {
    label: "Mongolia",
    value: "MNG",
    code: "976",
  },
  {
    label: "Montenegro",
    value: "MNE",
    code: "382",
  },
  {
    label: "Montserrat",
    value: "MSR",
    code: "1-664",
  },
  {
    label: "Morocco",
    value: "MAR",
    code: "212",
  },
  {
    label: "Mozambique",
    value: "MOZ",
    code: "258",
  },
  {
    label: "Myanmar",
    value: "MMR",
    code: "95",
  },
  {
    label: "Namibia",
    value: "NAM",
    code: "264",
  },
  {
    label: "Nauru",
    value: "NRU",
    code: "674",
  },
  {
    label: "Nepal",
    value: "NPL",
    code: "977",
  },
  {
    label: "Netherlands",
    value: "NLD",
    code: "31",
  },
  {
    label: "Netherlands Antilles",
    value: "ANT",
    code: "599",
  },
  {
    label: "New Caledonia",
    value: "NCL",
    code: "687",
  },
  {
    label: "New Zealand",
    value: "NZL",
    code: "64",
  },
  {
    label: "Nicaragua",
    value: "NIC",
    code: "505",
  },
  {
    label: "Niger",
    value: "NER",
    code: "227",
  },
  {
    label: "Nigeria",
    value: "NGA",
    code: "234",
  },
  {
    label: "Niue",
    value: "NIU",
    code: "683",
  },
  {
    label: "Norfolk Island",
    value: "NFK",
    code: "672",
  },
  {
    label: "Northern Mariana Islands",
    value: "MNP",
    code: "1-670",
  },
  {
    label: "Norway",
    value: "NOR",
    code: "47",
  },
  {
    label: "Oman",
    value: "OMN",
    code: "968",
  },
  {
    label: "Pakistan",
    value: "PAK",
    code: "92",
  },
  {
    label: "Palau",
    value: "PLW",
    code: "680",
  },
  {
    label: "Palestinian Territory, Occupied",
    value: "PSE",
    code: "970",
  },
  {
    label: "Panama",
    value: "PAN",
    code: "507",
  },
  {
    label: "Papua New Guinea",
    value: "PNG",
    code: "675",
  },
  {
    label: "Paraguay",
    value: "PRY",
    code: "595",
  },
  {
    label: "Peru",
    value: "PER",
    code: "51",
  },
  {
    label: "Philippines",
    value: "PHL",
    code: "63",
  },
  {
    label: "Pitcairn",
    value: "PCN",
    code: "64",
  },
  {
    label: "Poland",
    value: "POL",
    code: "48",
  },
  {
    label: "Portugal",
    value: "PRT",
    code: "351",
  },
  {
    label: "Puerto Rico",
    value: "PRI",
    code: "1-787, 1-939",
  },
  {
    label: "Qatar",
    value: "QAT",
    code: "974",
  },
  {
    label: "Romania",
    value: "ROU",
    code: "40",
  },
  {
    label: "Russian Federation",
    value: "RUS",
    code: "7",
  },
  {
    label: "Rwanda",
    value: "RWA",
    code: "250",
  },
  {
    label: "Réunion",
    value: "REU",
    code: "262",
  },
  {
    label: "Saint Barthélemy",
    value: "BLM",
    code: "590",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SHN",
    code: "290",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KNA",
    code: "1-869",
  },
  {
    label: "Saint Lucia",
    value: "LCA",
    code: "1-758",
  },
  {
    label: "Saint Martin (French part)",
    value: "MAF",
    code: "590",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "SPM",
    code: "508",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VCT",
    code: "1-784",
  },
  {
    label: "Samoa",
    value: "WSM",
    code: "685",
  },
  {
    label: "San Marino",
    value: "SMR",
    code: "378",
  },
  {
    label: "Sao Tome and Principe",
    value: "STP",
    code: "239",
  },
  {
    label: "Saudi Arabia",
    value: "SAU",
    code: "966",
  },
  {
    label: "Senegal",
    value: "SEN",
    code: "221",
  },
  {
    label: "Serbia",
    value: "SRB",
    code: "381",
  },
  {
    label: "Seychelles",
    value: "SYC",
    code: "248",
  },
  {
    label: "Sierra Leone",
    value: "SLE",
    code: "232",
  },
  {
    label: "Singapore",
    value: "SGP",
    code: "65",
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "SXM",
    code: "1-721",
  },
  {
    label: "Slovakia",
    value: "SVK",
    code: "421",
  },
  {
    label: "Slovenia",
    value: "SVN",
    code: "386",
  },
  {
    label: "Solomon Islands",
    value: "SLB",
    code: "677",
  },
  {
    label: "Somalia",
    value: "SOM",
    code: "252",
  },
  {
    label: "South Africa",
    value: "ZAF",
    code: "27",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "SGS",
    code: "500",
  },
  {
    label: "South Sudan",
    value: "SSD",
    code: "211",
  },
  {
    label: "Spain",
    value: "ESP",
    code: "34",
  },
  {
    label: "Sri Lanka",
    value: "LKA",
    code: "94",
  },
  {
    label: "Sudan",
    value: "SDN",
    code: "249",
  },
  {
    label: "Suriname",
    value: "SUR",
    code: "597",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "SJM",
    code: "47",
  },
  {
    label: "Swaziland",
    value: "SWZ",
    code: "268",
  },
  {
    label: "Sweden",
    value: "SWE",
    code: "46",
  },
  {
    label: "Switzerland",
    value: "CHE",
    code: "41",
  },
  {
    label: "Syrian Arab Republic",
    value: "SYR",
    code: "963",
  },
  {
    label: "Taiwan",
    value: "TWN",
    code: "886",
  },
  {
    label: "Tajikistan",
    value: "TJK",
    code: "992",
  },
  {
    label: "Tanzania, United Republic of",
    value: "TZA",
    code: "255",
  },
  {
    label: "Thailand",
    value: "THA",
    code: "66",
  },
  {
    label: "Timor-Leste",
    value: "TLS",
    code: "670",
  },
  {
    label: "Togo",
    value: "TGO",
    code: "228",
  },
  {
    label: "Tokelau",
    value: "TKL",
    code: "690",
  },
  {
    label: "Tonga",
    value: "TON",
    code: "676",
  },
  {
    label: "Trinidad and Tobago",
    value: "TTO",
    code: "1-868",
  },
  {
    label: "Tunisia",
    value: "TUN",
    code: "216",
  },
  {
    label: "Turkey",
    value: "TUR",
    code: "90",
  },
  {
    label: "Turkmenistan",
    value: "TKM",
    code: "993",
  },
  {
    label: "Turks and Caicos Islands",
    value: "TCA",
    code: "1-649",
  },
  {
    label: "Tuvalu",
    value: "TUV",
    code: "688",
  },
  {
    label: "Uganda",
    value: "UGA",
    code: "256",
  },
  {
    label: "Ukraine",
    value: "UKR",
    code: "380",
  },
  {
    label: "United Arab Emirates",
    value: "ARE",
    code: "971",
  },
  {
    label: "United Kingdom",
    value: "GBR",
    code: "44",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "UMI",
    code: "1",
  },
  {
    label: "Uruguay",
    value: "URY",
    code: "598",
  },
  {
    label: "Uzbekistan",
    value: "UZB",
    code: "998",
  },
  {
    label: "Vanuatu",
    value: "VUT",
    code: "678",
  },
  {
    label: "Venezuela, Bolivarian Republic of",
    value: "VEN",
    code: "58",
  },
  {
    label: "Viet Nam",
    value: "VNM",
    code: "84",
  },
  {
    label: "Virgin Islands, British",
    value: "VGB",
    code: "1-284",
  },
  {
    label: "Virgin Islands, U.S.",
    value: "VIR",
    code: "1-340",
  },
  {
    label: "Wallis and Futuna",
    value: "WLF",
    code: "681",
  },
  {
    label: "Western Sahara",
    value: "ESH",
    code: "212",
  },
  {
    label: "Yemen",
    value: "YEM",
    code: "967",
  },
  {
    label: "Zambia",
    value: "ZMB",
    code: "260",
  },
  {
    label: "Zimbabwe",
    value: "ZWE",
    code: "263",
  },
  {
    label: "Åland Islands",
    value: "ALA",
    code: "358",
  },
];

export const states = [
  {
      "label": "Alabama",
      "value": "AL"
  },
  {
      "label": "Alaska",
      "value": "AK"
  },
  // {
  //     "label": "American Samoa",
  //     "value": "AS"
  // },
  {
      "label": "Arizona",
      "value": "AZ"
  },
  {
      "label": "Arkansas",
      "value": "AR"
  },
  {
      "label": "California",
      "value": "CA"
  },
  {
      "label": "Colorado",
      "value": "CO"
  },
  {
      "label": "Connecticut",
      "value": "CT"
  },
  {
      "label": "Delaware",
      "value": "DE"
  },
  // {
  //     "label": "District Of Columbia",
  //     "value": "DC"
  // },
  // {
  //     "label": "Federated States Of Micronesia",
  //     "value": "FM"
  // },
  {
      "label": "Florida",
      "value": "FL"
  },
  {
      "label": "Georgia",
      "value": "GA"
  },
  // {
  //     "label": "Guam",
  //     "value": "GU"
  // },
  {
      "label": "Hawaii",
      "value": "HI"
  },
  {
      "label": "Idaho",
      "value": "ID"
  },
  {
      "label": "Illinois",
      "value": "IL"
  },
  {
      "label": "Indiana",
      "value": "IN"
  },
  {
      "label": "Iowa",
      "value": "IA"
  },
  {
      "label": "Kansas",
      "value": "KS"
  },
  {
      "label": "Kentucky",
      "value": "KY"
  },
  // {
  //     "label": "District Of Columbia",
  //     "value": "DC"
  // },
  // {
  //     "label": "Federated States Of Micronesia",
  //     "value": "FM"
  // },
  {
      "label": "Louisiana",
      "value": "LA"
  },
  {
      "label": "Maine",
      "value": "ME"
  },
  // {
  //     "label": "Marshall Islands",
  //     "value": "MH"
  // },
  {
      "label": "Maryland",
      "value": "MD"
  },
  {
      "label": "Massachusetts",
      "value": "MA"
  },
  {
      "label": "Michigan",
      "value": "MI"
  },
  {
      "label": "Minnesota",
      "value": "MN"
  },
  {
      "label": "Mississippi",
      "value": "MS"
  },
  {
      "label": "Missouri",
      "value": "MO"
  },
  {
      "label": "Montana",
      "value": "MT"
  },
  {
      "label": "Nebraska",
      "value": "NE"
  },
  {
      "label": "Nevada",
      "value": "NV"
  },
  // {
  //     "label": "Marshall Islands",
  //     "value": "MH"
  // },
  {
      "label": "New Hampshire",
      "value": "NH"
  },
  {
      "label": "New Jersey",
      "value": "NJ"
  },
  {
      "label": "New Mexico",
      "value": "NM"
  },
  {
      "label": "New York",
      "value": "NY"
  },
  {
      "label": "North Carolina",
      "value": "NC"
  },
  {
      "label": "North Dakota",
      "value": "ND"
  },
  // {
  //     "label": "Northern Mariana Islands",
  //     "value": "MP"
  // },
  {
      "label": "Ohio",
      "value": "OH"
  },
  {
      "label": "Oklahoma",
      "value": "OK"
  },
  {
      "label": "Oregon",
      "value": "OR"
  },
  // {
  //     "label": "Palau",
  //     "value": "PW"
  // },
  {
      "label": "Pennsylvania",
      "value": "PA"
  },
  // {
  //     "label": "Puerto Rico",
  //     "value": "PR"
  // },
  {
      "label": "Rhode Island",
      "value": "RI"
  },
  {
      "label": "South Carolina",
      "value": "SC"
  },
  {
      "label": "South Dakota",
      "value": "SD"
  },
  // {
  //     "label": "Palau",
  //     "value": "PW"
  // },
  {
      "label": "Tennessee",
      "value": "TN"
  },
  // {
  //     "label": "Puerto Rico",
  //     "value": "PR"
  // },
  {
      "label": "Texas",
      "value": "TX"
  },
  {
      "label": "Utah",
      "value": "UT"
  },
  {
      "label": "Vermont",
      "value": "VT"
  },
  // {
  //     "label": "Virgin Islands",
  //     "value": "VI"
  // },
  {
      "label": "Virginia",
      "value": "VA"
  },
  {
      "label": "Washington",
      "value": "WA"
  },
  {
      "label": "West Virginia",
      "value": "WV"
  },
  {
      "label": "Wisconsin",
      "value": "WI"
  },
  // {
  //     "label": "Virgin Islands",
  //     "value": "VI"
  // },
  {
      "label": "Wyoming",
      "value": "WY"
  }
];

export const howHeared_cert = [
  { label: "Email from the USC", value: "Email from the USC" },
  { label: "Social Media", value: "Social Media" },
  { label: "Family or Friend", value: "Family or Friend" },
  { label: "An online search", value: "An online search" },
];

export const gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const regardless = [
  {
    label:
      "American Indian/Alaskan native (including all Original Peoples of the Americas)",
    value:
      "American Indian/Alaskan native (including all Original Peoples of the Americas)",
  },
  {
    label: "Asian (including Indian subcontinent and Philippines)",
    value: "Asian (including Indian subcontinent and Philippines)",
  },
  {
    label: "Black or African-American (including Africa and Caribbean)",
    value: "Black or African-American (including Africa and Caribbean)",
  },
  {
    label: "Native Hawaiian or Other Pacific Islander (Original Peoples)",
    value: "Native Hawaiian or Other Pacific Islander (Original Peoples)",
  },
  {
    label: "White (including Middle Eastern)",
    value: "White (including Middle Eastern)",
  },
];

export const Educationlevel = [
  { label: "High school or equivalent ", value: "High school or equivalent " },
  { label: "Associate degree", value: "Associate degree" },
  { label: "Bachelor’s degree", value: "Bachelor’s degree" },
  { label: "Master’s degree", value: "Master’s degree" },
  {
    label: "Doctorate or Professional degree",
    value: "Doctorate or Professional degree",
  },
];

export const grade = [
  { label: "Freshman", value: "Freshman" },
  { label: "Sophomore", value: "Sophomore" },
  { label: "Junior", value: "Junior" },
  { label: "Senior", value: "Senior" },
];

export const workexperience = [
  { label: "0 - 2", value: "0 - 2" },
  { label: "3 - 6", value: "3 - 6" },
  { label: "7 - 10", value: "7 - 10" },
  { label: "10+", value: "10+" },
];

export const residency = [
  {
    label: "I am a citizen of the United States",
    value: "I am a citizen of the United States",
  },
  {
    label: "I am a citizen of the United States, currently living abroad",
    value: "I am a citizen of the United States, currently living abroad",
  },
  {
    label: "I am a permanent resident of the United States, with a green card",
    value: "I am a permanent resident of the United States, with a green card",
  },
  {
    label:
      "I am an international student (non- U.S. citizen, non- U.S. permanent resident)",
    value:
      "I am an international student (non- U.S. citizen, non- U.S. permanent resident)",
  },
  {
    label:
      "I am an international student, currently studying in the U.S. (non- U.S. citizen, non- U.S. permanent resident)",
    value:
      "I am an international student, currently studying in the U.S. (non- U.S. citizen, non- U.S. permanent resident)",
  },
];

export const initialRadioOption = [
  { label: "Yes", value: "Yes" },
  {
    label: "No, not of Hispanic, Latino or Spanish origin",
    value: "No, not of Hispanic, Latino or Spanish origin",
  },
];

export const addressRadio = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const intialStepData = [
  { step: 1, title: "Applicant Information", completed: false, active: true },
  { step: 2, title: "Course Information", completed: false, active: false },
  { step: 3, title: "Payment", completed: false, active: false },
];

const addressFields = [
  {
    type: "input",
    title: "Address",
    name: "parent_address",
  },
  {
    type: "input",
    title: "Address 2",
    name: "parent_address_2",
  },
  {
    type: "input",
    title: "City",
    name: "parent_city",
  },
  {
    type: "input",
    title: "State",
    name: "state",
  },
  {
    type: "input",
    title: "Province/County",
    name: "province_county",
  },
  {
    type: "CustomSelect",
    title: "Country",
    name: "parent_country",
    optionKey: "countries",
    mode: "",
    placeholder: "Select a country",
    options: countries,
  },
  {
    type: "input",
    title: "Zip/Postal Code",
    name: "parent_zip_postal_code",
  },
];

export const parentInfo = [
  {
    type: "input",
    title: "First Name",
    name: "first_name",
  },
  {
    type: "input",
    title: "Last Name",
    name: "last_name",
  },
  {
    type: "input",
    title: "Relationship",
    name: "relationship",
  },
  {
    type: "CustomRadio",
    title: "Address Same as Student",
    name: "address_same_as_student",
    optionKey: "addressRadio",
    options: addressRadio,
  },
  {
    type: "input",
    title: "Home Phone",
    name: "parent_home_phone",
  },
  {
    type: "input",
    title: "Parent Cell Phone",
    name: "parent_cell_phone",
  },
  {
    type: "input",
    title: "Parent Email",
    name: "parent_email",
  },
];

export const parentInfo2 = [
  {
    type: "input",
    title: "First Name",
    name: "parent_first_name",
  },
  {
    type: "input",
    title: "Last Name",
    name: "parent_last_name",
  },
  {
    type: "input",
    title: "Relationship",
    name: "parent_relationship",
  },
  {
    type: "CustomRadio",
    title: "Address Same as Student",
    name: "parent_address_same_as_student",
    optionKey: "addressRadio",
    options: addressRadio,
  },
  {
    type: "input",
    title: "Home Phone",
    name: "parent_home_phone_2",
  },
  {
    type: "input",
    title: "Parent Cell Phone",
    name: "parent_cell_phone_2",
  },
  {
    type: "input",
    title: "Parent Email",
    name: "parent_email_2",
  },
];

export const expandedParentInfo = [
  {
    type: "input",
    title: "First Name",
    name: "first_name",
  },
  {
    type: "input",
    title: "Last Name",
    name: "last_name",
  },
  {
    type: "input",
    title: "Relationship",
    name: "relationship",
  },
  {
    type: "CustomRadio",
    title: "Address Same as Student",
    name: "address_same_as_student",
    optionKey: "addressRadio",
    options: addressRadio,
  },
  ...addressFields,
  {
    type: "input",
    title: "Home Phone",
    name: "parent_home_phone",
  },
  {
    type: "input",
    title: "Parent Cell Phone",
    name: "parent_cell_phone",
  },
  {
    type: "input",
    title: "Parent Email",
    name: "parent_email",
  },
];

export const expandedParentInfo2 = [
  {
    type: "input",
    title: "First Name",
    name: "parent_first_name",
  },
  {
    type: "input",
    title: "Last Name",
    name: "parent_last_name",
  },
  {
    type: "input",
    title: "Relationship",
    name: "parent_relationship",
  },
  {
    type: "CustomRadio",
    title: "Address Same as Student",
    name: "parent_address_same_as_student",
    optionKey: "addressRadio",
    options: addressRadio,
  },
  ...addressFields,
  {
    type: "input",
    title: "Home Phone",
    name: "parent_home_phone_2",
  },
  {
    type: "input",
    title: "Parent Cell Phone",
    name: "parent_cell_phone_2",
  },
  {
    type: "input",
    title: "Parent Email",
    name: "parent_email_2",
  },
];

export const startDates = ["30/11/2022", "01/12/2022", "02/12/2022"];

export const applicationRules = {
  most_recently_completed: [
    {
      required: true,
      message: "Please upload a file.",
    },
  ],
  select_timezone: [
    {
      required: true,
      message: "Timezone is required.",
    },
  ],
  about_yourself: [
    {
      required: true,
      message: "Please type something about yourself.",
    },
  ],
  url: [
    {
      validator: (_, value) => {
        const re =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        if (value && !re.test(String(value).toLowerCase())) {
          return Promise.reject("Please enter a valid link");
        } else {
          return Promise.resolve();
        }
      },
    },
  ],
  course_id: [
    {
      required: true,
      message: "Please select any course",
    },
  ],
  start_date: [
    {
      required: true,
      message: "Please select a course start date",
    },
  ],
  residency: [
    {
      required: true,
      message: "Please select an option",
    },
  ],
  identity: [
    {
      required: true,
      message: "Please select an option",
    },
  ],
  demographic_info: [
    {
      required: true,
      message: "Please select an option",
    },
  ],
  email: [
    {
      required: true,
      message: "Email is required",
    },
    {
      type: "email",
      message: "Email is invalid",
    },
  ],
  address: [
    {
      required: true,
      message: "Address is required.",
    },
  ],
  country: [
    {
      required: true,
      message: "Country is required.",
    },
  ],
  zip_code: [
    {
      required: true,
      message: "Zip code is required.",
    },
  ],
  high_school_name: [
    {
      required: true,
      message: "High school name is required.",
    },
  ],
  relationship: [
    {
      required: true,
      message: "Relationship is required.",
    },
  ],
  address_same_as_student: [
    {
      required: true,
      message: "Address same as student is required.",
    },
  ],
  counselor_name: [
    {
      required: true,
      message: "Counselor name is required.",
    },
  ],
  counselor_email: [
    {
      required: true,
      message: "Counselor email is required.",
    },
    {
      type: "email",
      message: "email is invalid",
    },
  ],
  current_grade: [
    {
      required: true,
      message: "Current grade is required.",
    },
  ],
  home_phone: [
    {
      required: true,
      message: "Home phone is required.",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }

        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length !== 10) {
          return Promise.reject("Please enter a 10-digit home phone number.");
        }
        return Promise.resolve();
      },
    }),
  ],
  cell_phone: [
    {
      required: true,
      message: "Cell phone is required.",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.resolve();
        }

        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length !== 10) {
          return Promise.reject("Please enter a 10-digit cell phone number.");
        }
        return Promise.resolve();
      },
    }),
  ],
  parent_cell_phone: [
    {
      required: true,
      message: "Cell phone is required.",
    },
  ],
  city: [
    {
      required: true,
      message: "City is required.",
    },
  ],
  date_of_birth: [
    {
      required: true,
      message: "Date of birth is required.",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const bod = new Date(value)
        const carentDate  =  new Date()
        
        if(isNaN(Date.parse(value)) && value){
          return Promise.reject("Please enter a valid date.");
        }else if(bod >= carentDate){
          return Promise.reject("Date of birth cannot be a future date.");
        }
        else{
          return Promise.resolve();
        }
      },
    }),
  ],
  
  
  first_name: [
    {
      required: true,
      message: "First name is required.",
    },
  ],
  program_name:[
    {
      required: true,
      message: "Program name is required.",
    },
  ],
  program_start_date:[
    {
      required: true,
      message: "Program start date is required.",
    },
  ],

  parent_email: [
    {
      required: true,
      message: "Email is required",
    },
    {
      type: "email",
      message: "Email is invalid.",
    },
  ],
  last_name: [
    {
      required: true,
      message: "Last name is required.",
    },
  ],
  how_did_hear_about: [
    {
      required: true,
      message: "Please select an option.",
    },
  ],
  gender: [
    {
      required: true,
      message: "Please select an option.",
    },
  ],
  confirm_age: [
    {
      required: true,
      message: "Please select an option.",
    },
  ],
  state: [
    {
      required: true,
      message: "State is required.",
    },
  ],
  highest_education: [
    {
      required: true,
      message: "Please highest education is required.",
    },
  ],
  work_experience: [
    {
      required: true,
      message: "Please work experience is required.",
    },
  ],
  senior_role:[
    {
      required: true,
      message: "Senior role is required.",
    },
  ],
  organization_name:[
    {
      required: true,
      message: "Organization name is required. ",
    },
  ],
  // current_role:[
  //   {
  //     required: true,
  //     message: "Current role is required.",
  //   },
  // ],
  current_organization:[
    {
      required: true,
      message: "Current organization is required. ",
    },
  ],
  leadership_experience:[
    {
      required: true,
      message: "Please select an option.",
    },
  ],
  cv_url:[
    {
      required: true,
      message: "CV is required.",
    },
  ],
  personal_goals:[
    {
      required: true,
      message: "Personal goals is required.",
    },
  ],
  participate_interaction:[
    {
      required: true,
      message: "Participate interaction is required. ",
    },
  ],
  how_you_learn:[
    {
      required: true,
      message: "How did you learn is required. ",
    },
  ],
  
  personal_ref_name:[
    {
      required: true,
      message: "Name is required.",
    },
  ],
  personal_ref_email:[
    {
      required: true,
      message: "Email is required.",
    },
    {
      type: "email",
      message: "Email is invalid.",
    },
  ],
  personal_ref_relation:[
    {
      required: true,
      message: "Relationship is required.",
    },
  ],
  prof_ref_name:[
    {
      required: true,
      message: "Name is required.",
    },
  ],
 prof_ref_email:[
  {
    required: true,
    message: "Email is required.",
  },
  {
    type: "email",
    message: "Email is invalid.",
  },
  ],
  prof_ref_relation:[
    {
      required: true,
      message: "Relationship is required.",
    },
  ],
};

export const timeZone = [
  "EST Eastern Standard Time GMT-5:00",
  "CST Central Standard Time GMT-6:00",
  "MST Mountain Standard Time GMT-7:00",
  "PST Pacific Standard Time GMT-8:00",
  "AST Alaska Standard Time GMT-9:00",
  "HST Hawaii Standard Time GMT-10:00",
  "GMT Greenwich Mean Time GMT",
  "UTC Universal Coordinated Time GMT",
  "ECT European Central Time GMT+1:00",
  "EET Eastern European Time GMT+-2:00",
  "ART (Arabic) Egypt Standard Time GMT+2:00",
  "EAT Eastern African Time GMT+3:00",
  "MET Middle East Time GMT+3:30",
  "NET Near East Time GMT+4:00",
  "PLT Pakistan Lahore Time GMT+5:00",
  "IST India Standard Time GMT+5:30",
  "BST Bangladesh Standard Time GMT+6:00",
  "VST Vietnam Standard Time GMT+7:00",
  "CST China Standard Time GMT+8:00",
  "JST Japan Standard Time GMT+9:00",
  "ACT Australia Central Time GMT+9:30",
  "AET Australia Eastern Time GMT+10:00",
  "SST Solomon Standard Time GMT+11:00",
  "NST New Zealand Standard Time GMT+12:00",
  "MIT Midway Islands Time GMT-11:00",
  "PNT Phoenix Standard Time GMT-7:00",
  "IET Indiana Eastern Standard Time GMT-5:00",
  "PRT Puerto Rico and US Virgin lslands Time GMT-4:00",
  "CNT Canada Newfoundland Time GMT-3:30",
  "AGT Argentina Standard Time GMT-3:00",
  "BET Brazil Eastern Time GMT-3:00",
  "CAT Central African Time GMT-1:00",
];

const industry = [
  { label: "AI/Robotics", value: "AI/Robotics" },
  { label: "Accounting", value: "Accounting" },
  { label: "Advertising", value: "Advertising" },
  { label: "Agriculture", value: "Agriculture" },
  { label: "Airlines and Aviation", value: "Airlines and Aviation" },
  { label: "Architecture and Planning", value: "Architecture and Planning" },
  { label: "Arts", value: "Arts" },
  { label: "Banking", value: "Banking" },
  { label: "Biotechnology", value: "Biotechnology" },
  {
    label: "Broadcast and Digital Media",
    value: "Broadcast and Digital Media",
  },
  { label: "Child Care", value: "Child Care" },
  { label: "Computer Science", value: "Computer Science" },
  { label: "Construction", value: "Construction" },
  { label: "Consulting", value: "Consulting" },
  { label: "Consumer Products", value: "Consumer Products" },
  { label: "Dance", value: "Dance" },
  { label: "Dentistry", value: "Dentistry" },
  { label: "Digital Communication", value: "Digital Communication" },
  { label: "E-Commerce", value: "E-Commerce" },
  { label: "Education: K-12", value: "Education: K-12" },
  { label: "Education: Postsecondary", value: "Education: Postsecondary" },
  { label: "Electronics", value: "Electronics" },
  { label: "Energy", value: "Energy" },
  { label: "Engineering", value: "Engineering" },
  { label: "Environment", value: "Environment" },
  { label: "Events and Meeting Planning" },
  { label: "Fashion", value: "Fashion" },
  { label: "Film", value: "Film" },
  { label: "Finance", value: "Finance" },
  { label: "Financial Services", value: "Financial Services" },
  {
    label: "Food and Beverage Preparation and Services",
    value: "Food and Beverage Preparation and Services",
  },
  { label: "Government", value: "Government" },
  {
    label: "Health and Hospital Administration",
    value: "Health and Hospital Administration",
  },
  { label: "Health and Medicine", value: "Health and Medicine" },
  { label: "Home Services", value: "Home Services" },
  { label: "Hotels and Resorts", value: "Hotels and Resorts" },
  { label: "Human Resources", value: "Human Resources" },
  { label: "Industrial Trades", value: "Industrial Trades" },
  { label: "Insurance", value: "Insurance" },
  { label: "Interactive Media", value: "Interactive Media" },
  { label: "International Relations", value: "International Relations" },
  { label: "Investment and Securities", value: "Investment and Securities" },
  { label: "Journalism", value: "Journalism" },
  { label: "Law", value: "Law" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Market Research", value: "Market Research" },
  { label: "Marketing", value: "Marketing" },
  { label: "Media and Entertainment", value: "Media and Entertainment" },
  { label: "Medical Technology", value: "Medical Technology" },
  { label: "Military", value: "Military" },
  { label: "Modeling and Cosmetology", value: "Modeling and Cosmetology" },
  { label: "Mortuary Services", value: "Mortuary Services" },
  { label: "Museum Work", value: "Museum Work" },
  { label: "Music", value: "Music" },
  { label: "Non-Profit", value: "Non-Profit" },
  { label: "Optometry", value: "Optometry" },
  { label: "Outdoor and Adventure", value: "Outdoor and Adventure" },
  { label: "Pharmacy/Pharmaceuticals", value: "Pharmacy/Pharmaceuticals" },
  { label: "Print and Digital Media", value: "Print and Digital Media" },
  { label: "Public Health", value: "Public Health" },
  { label: "Public Relations", value: "Public Relations" },
  { label: "Publishing", value: "Publishing" },
  { label: "Radio", value: "Radio" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Religious Services", value: "Religious Services" },
  { label: "Retail/Wholesale", value: "Retail/Wholesale" },
  { label: "Small Business", value: "Small Business" },
  { label: "Social Work", value: "Social Work" },
  { label: "Sports and Recreation", value: "Sports and Recreation" },
  {
    label: "Staffing and Executive Search",
    value: "Staffing and Executive Search",
  },
  {
    label: "Technology/Technological Sciences",
    value: "Technology/Technological Sciences",
  },
  { label: "Telecommunications", value: "Telecommunications" },
  { label: "Television and Radio", value: "Television and Radio" },
  { label: "Theater", value: "Theater" },
  { label: "Tourism", value: "Tourism" },
  { label: "Transportation", value: "Transportation" },
  { label: "Travel and Hospitality", value: "Travel and Hospitality" },
  { label: "Utilities", value: "Utilities" },
  { label: "Veterinary Medicine", value: "Veterinary Medicine" },
  { label: "Visual Arts", value: "Visual Arts" },
];

export const howabout = [
  {label:'LinkedIn', value:'LinkedIn'},
  {label:'Online Search', value:'Online Search'},
]

export const howHeared = [
  {
    label: "No Poverty: End poverty in all its forms everywhere.",
    value: "No Poverty: End poverty in all its forms everywhere.",
    checked: false,
  },
  {
    label:
      "Zero Hunger: End hunger, achieve food security and improved nutrition, and promote sustainable agriculture.",
    value:
      "Zero Hunger: End hunger, achieve food security and improved nutrition, and promote sustainable agriculture. ",
    checked: false,
  },
  {
    label: `Decent Work and Economic Growth: Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.`,
    value: `Decent Work and Economic Growth: Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.`,
    checked: false,
  },
  {
    label: `Reduced Inequalities: Reduce income inequality within and among countries.`,
    value: `Reduced Inequalities: Reduce income inequality within and among countries.`,
    checked: false,
  },
  {
    label: `Gender Equality: Achieve gender equality and empower all women and girls.`,
    value: `Gender Equality: Achieve gender equality and empower all women and girls.`,
    checked: false,
  },
  {
    label: `Peace, Justice and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable, and inclusive institutions at all levels`,
    value: `Peace, Justice and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable, and inclusive institutions at all levels`,
    checked: false,
  },
];




const SocialImpactComponent = [
  {
    des: "During this year, our Leaders will develop project plans or pursue pathways focused on social good and will serve as a central focus for their next phase of life.  We are aligning social impact categories through the Sustainable Development Goals set out by the UN, combined into three key categories: ",
    li_list: [
      { li: "Creating Equal Opportunity " },
      { li: "Sustainability" },
      { li: "Health, Education and Well-Being" },
    ],
  },
];

const  foremostinterested = [
  {
    des: "We are first and foremost interested in your individual goals and suitability for the program. We are also committed to creating the best possible experience for all of our applicants and part of that is understanding how each individual fits into the whole. We expect that participants can commit fully to the program during the entire period so will be evaluating that commitment. ",
    
  },
];

const opt_it = [
  {
    des:"We may occasionally reach out to you with further information about USC Distinguished Leaders. Please check the box to opt-in."
  }
]
export const applicationFormat = [
  {
    id: 1,
    key: "personal_information",
    title: "personal information",
    children: [
      {
        type: "input",
        title: "Program Name",
        name: "program_name",
        placeholder: 'Please enter   program name',
        disabled: true,
      },
      {
        type: 'CustomSelect',
        title: 'Program start date',
        name: 'program_start_date',
        optionKey: 'courseDate',
        placeholder: 'Please Select the start date',
        customRender: true,
        disabled: true,
      },
      {
        type: "input",
        title: "Legal first name",
        name: "first_name",
        disabled: true,
      },
      {
        type: "input",
        title: "Legal last name",
        name: "last_name",
        disabled: true,
      },
      {
        type: "input",
        title: "Email",
        name: "email",
        onBlurTrack: true,
        disabled: true,
      },
      {
        type: "input",
        title: "Preferred Phone",
        name: "cell_phone",
        maxLength: 10,
        pattern: "[0-9]*",
        placeholder: "5555555555",
      },
      {
        type: "input",
        title: "Preferred Name",
        name: "preffered_name",
      
      },
      {
        type: "DateInput",
        title: "Date of Birth",
        name: "date_of_birth",
      },
      {
        type: "CostomGoogleLookup",
        title: "Address",
        name: "address",
      },
      {
        type: "input",
        title: "Address 2",
        name: "address2",
      },
      {
        type: "input",
        title: "City",
        name: "city",
      },
      {
        type: "CustomSelect",
        title: "State",
        name: "state",
        optionKey: "State",
        mode: "",
        placeholder: "Select a State",
        options: states,
      },
      {
        type: "input",
        title: "Province/County",
        name: "province_county",
      },
      {
        type: "CustomSelect",
        title: "Country",
        name: "country",
        optionKey: "countries",
        mode: "",
        placeholder: "Select a country",
        options: countries,
        onBlurTrack: true,
      },
      {
        type: "input",
        title: "Zip/Postal Code",
        name: "zip_code",
      },
       {
        type: "CustomDetails",
        class:"firstand",
        options: opt_it,
      },
      {
        type: "CustomDetails",
        class:"firstand",
        options: opt_it,
      },
      {
        type: "CustomCheckbox",
        title:"",
        name: "further_information",
        datakey: "further_information",
        optionKey: "further_information",
        mode: "",
        options: {
          label: <>Yes, please send me more information</>,
          value: <>Yes, please send me more information</>,
        },
      },
    ],
  },
  {
    id: 2,
    key: "professional_background",
    title: "Professional background  ",
    children: [
      {
        type: "input",
        title: `Title of your most senior role (as it appears on your CV)`,
        note: `This is not necessarily your current role/title, but rather the most senior leadership position achieved during your career. Please list your title as it appears on your CV`,
        name: "senior_role",
      },
      {
        type: "input",
        title: `Name of the organization where you held your most senior role.`,
        name: "organization_name",
      },
      {
        type: "input",
        title: `Current title, If different than your most senior role in question #2.`,
        name: "current_role",
      },
      {
        type: "input",
        title: `Current organization, if different than the organization where you held your most senior role in question #3.`,
        name: "current_organization",
      },
      {
        type: "CustomSelect",
        title: `To better understand your leadership experience, please select the industry sector that best aligns with your career ::`,
        name: "leadership_experience",
        optionKey: "leadership_experience",
        mode: "multiple",
        placeholder: "select a type",
        options: industry,
      },
      {
        type: "CustomSelect",
        title: `Secondary or additional industry sector: (Optional) Please select a secondary or additional industry that aligns with your career ::`,
        name: "secondary_leadership_experience",
        optionKey: "secondary_leadership_experience",
        mode: "multiple",
        placeholder: "select a type",
        options: industry,
      },
      {
        type: "CustomFileUpload",
        title: `Attach current CV indicating leadership accomplishments.`,
        name: "cv_url",
      },
      {
        type: "textarea",
        title: `Please add any other websites that will help us get to know you better (Linked In, personal website, portfolio, etc) (Optional)`,
        name: "website",
      },
      {
        type: "textarea",
        title: `Please describe why you want to participate in this program, and how it aligns with your personal and professional goals. (500 words)`,
        name: "personal_goals",
      },
      {
        type: "CustomDetails",
        class:"firstand",
        options: foremostinterested,
      },
      {
        type: "CustomRadio",
        title: `If selected, are you able to dedicate the time required to participate fully in this program, spending one week each quarter in person, with weekly interaction with the program online for nine months? `,
        name: "participate_interaction",
        optionKey: "addressRadio",
        options: addressRadio,
      },
      {
        type: 'CustomSpecificCheckbox',
        title: `How did you learn about USC Distinguished Leaders program? (Please select all that apply)`,
        name: "how_you_learn",
        datakey: 'heardItem',
        optionKey: 'how_you_learn',
        mode: 'multiple',
        options: howabout
      },
    ],
  },
  {
    id: 3,
    key: "References",
    title: "References",
    children: [
      {
        type: "input",
        title: `Name`,
        main_title:"Personal Reference",
        name: "personal_ref_name",
      },
      {
        type: "input",
        title: `Email `,
        name: "personal_ref_email",
      },
      {
        type: "input",
        title: `Relationship`,
        name: "personal_ref_relation",
      },
      {
        type: "input",
        title: `Name`,
        main_title:"Professional Reference",
        name: "prof_ref_name",
      },
      {
        type: "input",
        title: `Email `,
        name: "prof_ref_email",
      },
      {
        type: "input",
        title: `Relationship`,
        name: "prof_ref_relation",
      },
    ],
  },
];

export const AreasofInterest = [
  {
    id: 1,
    key: "Areas_of_Interest",
    title: "Interest areas",
    children: [
      {
        type: "CustomDetails",
        options: SocialImpactComponent,
      },
      {
        type: 'CostomOtCheckbox',
        title: 'Please select your area(s) of interest (pick as many as apply, 1 minimum) :: ',
        name: 'equal_opportunity',
        class:'creating_Oppo',
        allSelect:true,
        options:  {
          label: "Creating Equal Opportunity",
          value:"Creating Equal Opportunity",

        },
      },
      {
        type: 'CostomOtCheckbox',
        class:'creating_Oppo_child',
        title: '',
        name: 'no_poverty',
        options:  {
          label: "No Poverty: End poverty in all its forms everywhere.",
          value:"No Poverty: End poverty in all its forms everywhere.",
        },
      },
       {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'zero_hunger',
        options:  {
          label: "Zero Hunger: End hunger, achieve food security and improved nutrition, and promote sustainable agriculture.",
          value:"Zero Hunger: End hunger, achieve food security and improved nutrition, and promote sustainable agriculture.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'decent_work_growth',
        options:  {
          label: "Decent Work and Economic Growth: Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.",
          value:"Decent Work and Economic Growth: Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'inequalities',
        options:  {
          label: "Reduced Inequalities: Reduce income inequality within and among countries.",
          value:"Reduced Inequalities: Reduce income inequality within and among countries.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'gender_equality',
        options:  {
          label: "Gender Equality: Achieve gender equality and empower all women and girls.",
          value:"Gender Equality: Achieve gender equality and empower all women and girls.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'institutions',
        options:  {
          label: "Peace, Justice and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable, and inclusive institutions at all levels.",
          value:"Peace, Justice and Strong Institutions: Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable, and inclusive institutions at all levels.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo',
        name: 'sustainability',
        allSelect:true,
        options:  {
          label: "Sustainability",
          value:"Sustainability",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'sanitation',
        options:  {
          label: "Clean Water and Sanitation: Ensure availability and sustainable management of water and sanitation for all.",
          value:"Clean Water and Sanitation: Ensure availability and sustainable management of water and sanitation for all.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'clean_energy',
        options:  {
          label: "Affordable and Clean Energy: Ensure access to affordable, reliable, sustainable, and modern energy for all.",
          value:"Affordable and Clean Energy: Ensure access to affordable, reliable, sustainable, and modern energy for all.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'industry_infrastructure',
        options:  {
          label: "Industry, Innovation, and Infrastructure: Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation.",
          value:"Industry, Innovation, and Infrastructure: Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'sustainable_cities',
        options:  {
          label: "Sustainable Cities and Communities: Make cities and human settlements inclusive, safe, resilient, and sustainable.",
          value:"Sustainable Cities and Communities: Make cities and human settlements inclusive, safe, resilient, and sustainable.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'consumption_production',
        options:  {
          label: "Responsible Consumption and Production: Ensure sustainable consumption and production patterns.",
          value:"Responsible Consumption and Production: Ensure sustainable consumption and production patterns.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'climate_action',
        options:  {
          label: "Climate Action: Take urgent action to combat climate change and its impacts by regulating emissions and promoting developments in renewable energy.",
          value:"Climate Action: Take urgent action to combat climate change and its impacts by regulating emissions and promoting developments in renewable energy.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'life_below_water',
        options:  {
          label: "Life Below Water: Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.",
          value:"Life Below Water: Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'life_on_land',
        options:  {
          label: "Life on Land: Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
          value:"Life on Land: Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo',
        name: 'health_education',
        allSelect:true,
        options:  {
          label: "Health, Education and Well-Being",
          value:"Health, Education and Well-Being",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child',
        name: 'good_health',
        options:  {
          label: "Good Health and Well-being: Ensure healthy lives and promote well-being for all at all ages.",
          value:"Good Health and Well-being: Ensure healthy lives and promote well-being for all at all ages.",
        },
      },
      {
        type: 'CostomOtCheckbox',
        title: '',
        class:'creating_Oppo_child heath_well',
        name: 'quality_education',
        options:  {
          label: "Quality Education: Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
          value:"Quality Education: Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
        },
      },
      {
        type: "textarea",
        mainTitle:"Project or Pathway Idea",
        title: "If you have an idea about what project or pathway you would like to focus on, please briefly summarize it below. This is not required, there will be plenty of time to form a focus during the course. 500 words or less (Optional)",
        name: "project_pathway",
      },
    ],
  },
];
