import CustomModal from "../../hoc/CustomModal";
import { useEffect, useState } from "react";
import SamplePop from "../../component/PopContent/SamplePop";
import CustomInput from "../../component/CustomInput";
import FilledButton from "../../component/FilledButton";
import { Form, Input } from "antd";
import { customFieldValidator } from "../../utils";
import store from "../../redux/Store";
import { useSelector } from "react-redux";
import Loader from "../../layout/Loader";
import { useParams } from "react-router-dom";
import { generate } from "mailto-link";
import Cookies from 'universal-cookie';

const SignUp = ({ history, ...rest }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errMsg, setErrMsg] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  form.setFieldsValue(formData);
  const params = rest.match.params;
  const { dispatch } = store;
  const state = useSelector((state) => state);
  // const loading = state.loading.effects.SignupModel.registerUser;
  const { id } = useParams();
  const cookies = new Cookies();

  useEffect(() => {
    if (params?.token) {
      dispatch.SignupModel.verifyUser({
        token: params?.token,
        setFormData,
        history,
      });
    }
  }, [params]);

  useEffect(() => {
    const delay = 2000;
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleShow = (type) => {
    setShowPassword({ ...showPassword, [type]: !showPassword[type] });
  };

  const handleClear = (form) => {
    setFormData({
      ...formData,
      password: "",
      confirmPassword: "",
      first_name: "",
      last_name: "",
    });
    form.setFieldsValue({
      email: formData.email,
      password: "",
      confirmPassword: "",
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleError = (name) => {
    setErrMsg({ ...errMsg, [name]: "" });
  };

  const rules = {
    first_name: [
      {
        required: true,
        message: "First name is required.",
      },
    ],
    last_name: [
      {
        required: true,
        message: "Last name is required.",
      },
    ],
    email: [
      {
        validator: (_, value) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!value) {
            return Promise.reject("Please enter your email");
          } else if (!re.test(String(value).toLowerCase())) {
            return Promise.reject("Please enter a valid email");
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
    password: [
      {
        validator: (_, value) => {
          const re =
            /^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/;
          if (!value) {
            return Promise.reject("Please enter your password");
          } else if (!re.test(value)) {
            return Promise.reject("Not a valid password");
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: "Please enter your confirm password",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("New password and confirm password must be same")
          );
        },
      }),
    ],
  };

  const onFinish = (data) => {
    setLoading(true);
    let required = [];
    required = [
      { name: "first_name", fieldName: "First name", rule: "any" },
      { name: "last_name", fieldName: "Last name", rule: "any" },
    ];
    const errors = customFieldValidator(required, formData || {});

    if (Object.keys(errors)?.length > 0) {
      setErrMsg(errors);
      return false;
    }
    let programid = cookies.get('programid')
    let freeapply = cookies?.get('free_applied')

    let parsedFreeApply;
	if (freeapply) {
		try {
			parsedFreeApply = JSON.parse(freeapply);
		} catch (error) {

		}
	}


    const temp = { ...data, email:data?.email?.toLowerCase(), course_id: id,programid,free_applied:parsedFreeApply };
    delete temp.confirmPassword;
    dispatch.SignupModel.registerUser({ data: temp, setSubmitted, setLoading , history });
  };

  const handleOpenMail = () => {
    const recipient = "distlead@usc.edu";
    const subject = "Hello";
    const body = "This is the email body.";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div className="page_wrapper">
          <div className="signin">
            <div className="container">
                <div className="signup_contain">
                <h1 className="signup_title">Register For An Applicant Account</h1>
                    <p className="signup_des text_center">
                    Thank you for your interest in the USC Distinguished Leader Program. <br />
                    Please register for an account to access the program application.
                    </p>
                      <p className="signup_signin_link">Already have an account ?  <a className="Signin_btn" onClick={()=>history.push('/signin')}> Sign In</a> </p>
                </div>
              <div className="row">
                {!submitted ? (
                  <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    onFinishFailed={(err) => console.log(`err`, err)}
                    onReset={handleClear}
                    scrollToFirstError
                    initialValues={{}}
                  >
                    <Form.Item
                      name="first_name"
                      rules={rules.first_name}
                      className="form_group"
                    >
                      <div className="">
                        <label for="first_name">First name <span>*</span></label>
                        <Input
                          onFocus={() => handleError("first_name")}
                          autoComplete={"off"}
                          value={formData.first_name}
                          className="form_control"
                          name={"first_name"}
                          id="first_name"
                          onChange={handleChange}
                        />
                        {/* <p className="errormsg password_info">{errMsg.first_name}</p> */}
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      rules={rules.last_name}
                      className="form_group"
                    >
                      <div className="">
                        <label for="last_name">Last name<span> *</span></label>
                        <Input
                          onFocus={() => handleError("last_name")}
                          autoComplete={"off"}
                          value={formData.last_name}
                          className="form_control"
                          name={"last_name"}
                          id="last_name"
                          onChange={handleChange}
                        />
                        {/* <p className="errormsg password_info">{errMsg.last_name}</p> */}
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={rules.email}
                      className="form_group"
                    >
                      <CustomInput
                        label="Email *"
                        name="email"
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        inputClass="form_control"
                        // disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={rules.password}
                      className="form_item form_group "
                    >
                     
                      <CustomInput
                        label="Password *"
                        type="password"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="password"
                        inputClass="form_control"
                        pass_eye="grideye"
                        handleShow={handleShow}
                        showPassword={showPassword}
                        errorMessage="Password must be at least 8 characters and include one lowercase letter, one uppercase letter and one number."
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      dependencies={["password"]}
                      rules={rules.confirmPassword}
                      className="form_group "
                    >
                      <CustomInput
                        label="Confirm Password *"
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        handleShow={handleShow}
                        showPassword={showPassword}
                        className="password"
                        inputClass="form_control"
                        pass_eye="grideye"
                      />
                    </Form.Item>
                    <div className="form_group d_flex_center pt_1 pb_2">
                      {/* <button type='reset' className="btn btn_gray">clear</button> */}
                      <FilledButton
                        type="submit"
                        loading={loading}
                        value="Continue"
                        className="btn_primary dl_continue "
                      />
                    </div>
                    {/* <p className="deskView">By clicking “Submit” you agree to our <br /><span onClick={() => window.open('https://www.usc.edu/pages/usc-privacy-notice/')}><strong>Terms of Use and Privacy Policy</strong></span></p> */}
                  </Form>
                ) : (
                  <div className="col-12 signin signUp congratulations_page">
                    <h3>
                      Congratulations! We have sent you an email to confirm your
                      registration.
                    </h3>
                    <h3>
                      {" "}
                      Please follow the instructions in the email to access your
                      course.
                    </h3>
                    <br />
                    <h3>
                      If you do not receive the email, please reach out to{" "}
                      <span
                        className="congratulations_page_mail"
                        onClick={handleOpenMail}
                      >
                       distlead@usc.edu.
                      </span>{" "}
                    </h3>
                  </div>
                )}

                <CustomModal
                  open={modalOpen}
                  handleOpen={handleOpenModal}
                  resetPass="resetPass"
                >
                  <SamplePop />
                </CustomModal>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
