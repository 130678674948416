const SamplePop = ({ className = "signpage_modal" }) => {
    return (
        <div className="modal_pop">
            <div className={className}>
                {/* reset password*/}
                <div className="resetpassword">
                    <h4 className="mb_2">reset password</h4>
                    <p className="reset_content mb_3">Please submit your registered email address and we will send instructions to reset your password if we are able to locate the account.</p>
                    <div className="form_group">
                        <input type="text" placeholder="email" className="form_control mb_30" />
                    </div>
                    <div class="form_group mb_2">
                        <button type="reset" class="btn btn_gray">Clear</button>
                        <button type="submit" class="btn_primary ml_1">submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SamplePop