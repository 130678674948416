import { useEffect } from 'react';
import store from '../../redux/Store';
import BtnLoader from '../../layout/BtnLoader';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';

const CancelPayment = () => {
  const { dispatch } = store;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const cookies = new Cookies();
  let history = useHistory();
  

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      switch (params?.payment_type) {
        case 'application': {
          dispatch.CourseModel?.userApplicationCancel({
            status: params.payment_type === "application" ? "cancelled" : "",
            id: params.usc_payment_id,
            final_payment_status: params.payment_type === "course" ? "cancelled" : "",
          });
          break;
        }
        default: {
          setTimeout(() => {
            // window.location.href = 'https://precollege.usc.edu/';
            
            window.location.href = `${process.env.REACT_APP_FRONT_URL_Dl}`
          }, 1000);
          return;
        }
      }
    }
  }, []);

  return (
    <div className='success-screen'>
      <h3>You've cancelled your payment.</h3>
      <p>You're being redirected back to {process.env.REACT_APP_FRONT_URL_Dl}.</p>
      <div className='success-screen_loader'>
        <BtnLoader size={'large'} />
      </div>
    </div>
  );
};

export default CancelPayment;
