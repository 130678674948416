import { ReactComponent as UscTextLogo } from "../../assets/images/usc_online_text.svg";
import { ReactComponent as Warning } from "../../assets/images/warning.svg";
import React from "react";
import {useSelector} from "react-redux";
import BtnLoader from "../BtnLoader";

const UpdatePop = ({updateSiteVersion}) => {
    const loading = useSelector(state =>  state.loading.effects.SigninModel.updateVersion);

    return (
        <div className="update-pop">
            <div className="update-pop_header">
                <UscTextLogo />
            </div>
            <div className="update-pop_body">
                <Warning/>
                <h2>Dl Program has been updated</h2>
                <p>Please click the below button to continue.</p>
            </div>
            <div className="update-pop_footer">
                {
                    loading ? (
                        <button
                            className="cursor_pointer btn_primary update-pop_footer_btn-refresh"
                        >
                            <BtnLoader/>
                        </button>
                    ) : (
                        <button
                            onClick={updateSiteVersion}
                            className="cursor_pointer btn_primary update-pop_footer_btn-refresh"
                        >
                            Continue
                        </button>
                    )
                }
            </div>
        </div>
    )
};

export default UpdatePop