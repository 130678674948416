import Cookies from 'universal-cookie';
import axiosClient from "../../api/axiosClient";
import { useHistory } from 'react-router-dom';

const cookies = new Cookies();

const SigninModel = {
    state: {},
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },

    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async verifyUserSignIn(payload) {
            const { token, setFormData, history } = payload;
            axiosClient.get(`/get_dl_email_from_token/${token}`).then(res => {
                if (res.data.status === 200) {
                    // history.push('/signin') 
                    setFormData((old) => { return { ...old, email: res.data.email } });
                } else if (res.data.status === 400) {
                    // history.push('/home');
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async forgotPassword(data) {
            axiosClient.post(`/dl_forgot_password`, data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: "We have sent a reset password link to your e-mail. Check your mail box." });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Info", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
        async logInUser(payload) {
            const { data, history, handleOpenModal, setLoading } = payload;
            axiosClient.post('/dl_signin', data).then(async(res) => {
                setLoading(false);
                if (res.data.status === 200) {
                    const { token, userId } = res.data;
                    // this.setUserDetails(res.data.data);
                    // localStorage.setItem('dl_userDetails', JSON.stringify(res.data.data));
                    const programid  = cookies.get('programid')
                   
                    var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}u_id`, userId, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    await this.getUserDetails()
                    cookies.remove(`${'programid'}`, { path: '/', expires: d,  secure: true, sameSite: 'strict' });
                    cookies.set(`programid`, res.data?.data?.program_id, { path: '/',  secure: true, sameSite: 'strict' });
                    if(res.data.data.payment_status === "completed" && res.data.data.email &&  res.data.data.first_name){ 
                        if(res.data?.data?.status === "approve" &&  res?.data?.data?.tuition_payment_status === "completed" && res?.data?.data?.deposit_payment_status === "completed" ){
                            history.push(`/program/${res.data?.data?.program_id}`);
                        }else if(res.data.data.status === "approve" && res?.data?.data?.deposit_payment_status === "completed"){
                            // history.push(`/tuition-payment/${res.data?.data?.application_id}`);
                            history.push("/dl-application-status")
                        }else if(res.data.data.status === "approve"){
                            // history.push(`/deposit-payment/${res.data?.data?.application_id}`);
                            history.push("/dl-application-status")
                        }
                        history.push("/dl-application-status")
                            // history.push(`/program/${res.data?.data?.program_id}`);
                    }
                    else{
                        if(res.data?.data?.program_id){
                            if(res.data?.data?.free_applied)
                                history.push(`/freeapply/${res.data?.data?.program_id ? res.data?.data?.program_id : programid}`);
                            else
                                history.push(`/apply/${res.data?.data?.program_id ? res.data?.data?.program_id : programid}`);
                        }
                        else{
                            history.push(`/apply`);
                        }
                      
                    }
                } else if (res.data.status === 103) {
                    handleOpenModal('ErrorPop', { header: "Email confirmation required FOR", param: data.email, subHeader: data.email, body: 'Before you can access your course, please confirm your account with us. Check your inbox for a confirmation email asking you to confirm your registration.', body2: 'If you need a new confirmation email, please click below.', subBody: 'Resend Account Confirmation Email' });
                } else if (res.data.status === 400) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Info", body: res.data.message });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "LOGIN ERROR", subHeader: "", className: "signpage_modal exist_email error_massge", body: "The email or password you entered is incorrect. Please try again." });
                }
            }).catch(err => {
                setLoading(false);
                dispatch.ErrorModel.handleErrorPop({ header: "LOGIN ERROR", className: "signpage_modal exist_email error_massge", subHeader: "", body: "The email or password you entered is incorrect. Please try again." });
            });
        },
        async reSendMail(data) {
            axiosClient.post('/resend_mail_cert_user', data).then(res => {
                if (res.data.status === 200) {
                    dispatch.ErrorModel.handleErrorPop({ header: "Success", body: res.data.message });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.data.message });
            });
        },
        async getUserDetails() {
            await axiosClient.get('dl_user_detail').then(res => {
                var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                cookies.remove(`${'programid'}`, { path: '/', expires: d,  secure: true, sameSite: 'strict' });
                if(res?.data?.data?.programid){
                    cookies.set(`programid`, res?.data?.data?.programid, { path: '/',  secure: true, sameSite: 'strict' }); 
                }               
                this.setUserDetails(res.data.data);
                localStorage.setItem('dl_userDetails', JSON.stringify(res.data.data));
            })
        },
        async getLatestVersion(setUpdatePop, state) {
            await axiosClient.get(`/dl_get_latest_version`).then(res => {
                if (res.data.status === 200) {
                    let result = res.data.data;
                    if (result?.version_number !== state.SigninModel.version) {
                        setUpdatePop(true);
                    }
                }
            });
        },
        async updateVersion(setUpdatePop, state) {
            await axiosClient.put(`/dl_update_user_version`, {
                "user_id": state.SigninModel.user_id ?? state.SigninModel._id
            }).then(res => {
                if (res.data.status === 200) {
                    // this.setUserDetails(res.data.data);
                    // localStorage.setItem('userDetails', JSON.stringify(res.data.data));
                    window.location.reload();
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "error", body: res.data.message });
                }
            }).catch(err => {
                dispatch.ErrorModel.handleErrorPop({ header: "error", body: err.message });
            }).finally(() => {
                setUpdatePop(false)
            });
        },
    }),
};

export default SigninModel;