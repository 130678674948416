import Cookies from 'universal-cookie';
import axiosClient from "../../api/axiosClient";

const cookies = new Cookies();

const SignupModel = {
    state: {
        userDetails:[]
    },
    reducers: {
        // handle state changes with pure functions
        setUserDetails(state, payload) {
            return { ...payload };
        },
        setdlUserDetails(state, payload) {
            return {
              ...state,
              ['userDetails']:payload.data
            };
          },
    },
    effects: (dispatch) => ({
        async verifyUser(payload) {
            const { token, setFormData, history } = payload;
            axiosClient.get(`/get_dl_email_from_token/${token}`).then(res => {
                const programid  = cookies.get('programid')
                if (res.data.status === 200) {
                    if(res.data.skillup_user === "Yes"){
                        history.push('/signin');
                    }
                    setFormData((old) => { return { ...old, email: res.data.email, first_name: res.data.first_name, last_name: res.data.last_name } });
                } else if (res.data.status === 400) {
                    history.push(`/apply/${programid}`);
                } else {
                    console.log(`res`, res)
                }
            }).catch(err => {
                console.log(`err`, err)
            })
        },
        async registerUser(payload) {
            const { data, setSubmitted , setLoading , history } = payload;
            const programid  = cookies.get('programid')
            const freeapply  = cookies.get('free_applied')

            axiosClient.post('/dl_register', data).then(res => {
                setLoading(false);
                if (res.data.status === 200) {
                    setSubmitted(true);
                    this.setdlUserDetails({ name: 'userDetails', data: res.data.userId});
                    const { token, userId } = res.data;
                    var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}x-access-token`, token, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                    cookies.set(`${process.env.REACT_APP_PREFIX}u_id`, userId, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                    dispatch.ApplicationModel.trackFormInAc({
                        email: userId?.email,
                        firstname: userId?.first_name,
                        lastname: userId?.last_name,
                        formID: "Dl_ApplicantForm",
                      });
                      if(programid){
                        if(freeapply)
                            history.push(`/freeapply/${programid}`);
                        else
                            history.push(`/apply/${programid}`);
                      }else{
                        if(freeapply)
                        history.push('freeapply')
                        else
                        history.push(`/apply`);
                      }
                    
                } else if (res.data.status === 103) {
                    dispatch.ErrorModel.handleErrorPop({
                        header: "oops! There is some error in creating your profile", body: <>
                            Please contact us at <a className="pop_email" href='mailto:dl@usc.edu'>dl@usc.edu</a> to help you resolve this issue.
                        </>, bodyClass: 'mb_37'
                    });
                } else {
                    dispatch.ErrorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                setLoading(false);
                dispatch.ErrorModel.handleErrorPop({ header: "Error", body: err.response.data.message });
            });
        },
    }),
};

export default SignupModel;